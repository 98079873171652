<template>
  <div class="global-validation-frais">
    <b-button
      size="sm"
      class="button-export-style  ml-2"
      title="Valider des factures"
      @click.prevent="handleValidGlobal"
      ><font-awesome-icon icon="check-double" class="file-medical"
    /></b-button>
    <!-- facture to upload  template -->
    <b-modal
      no-close-on-backdrop
      ref="uploadFactureTemplateModal"
      id="uploadFactureTemplateModal"
      :title="
        getTemplateFilialeToUpload && getTemplateFilialeToUpload.name
          ? getTemplateFilialeToUpload.name
          : ''
      "
      size="lg"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-frais "
    >
      <div
        class="iconClose"
        @click.prevent="hideModal('uploadFactureTemplateModal')"
      >
        <font-awesome-icon icon="times" />
      </div>
      <div class="message text-aligne-center">
        <div
          v-if="getLoadingGetDataTemplate"
          class="chargement-message-calender "
        >
          Chargement en cours...
        </div>
        <div v-if="getErrorTemplateDataToUpploadeed" class="error-message">
          <div class="error">{{ getErrorTemplateDataToUpploadeed }}</div>
        </div>
      </div>

      <div
        class="global-template-block-style"
        v-if="getTemplateFilialeToUpload"
        id="hmt_block_id"
      >
        <div class="input-fields-style-for-template">
          <div class="form-actions mt-3 mb-5 text-aligne-center">
            <b-button
              @click="handleUpload"
              class="button-succes-style ml-2"
              size="sm"
              variant="success"
            >
              Valider
              <font-awesome-icon icon="arrow-alt-circle-right" class="ml-2" />
            </b-button>
            <div class="message text-aligne-center">
              <div v-if="loadingUpload" class="chargement-message-calender ">
                Chargement en cours...
              </div>
              <div v-if="errorUpload" class="error-message">
                <div class="error">{{ errorUpload }}</div>
              </div>
            </div>
          </div>
          <div class="fields-input">
            <div></div>
            <div>
              <b-form-group
                label="Date de Facture"
                label-for="title-template-swift"
                class=" input-modal-champ "
              >
                <date-picker
                  value-type="format"
                  format="DD-MM-YYYY"
                  type="date"
                  :value="date"
                  class="inputDateTemplate"
                  @input="
                    changeFieldsValue({
                      key: 'date_id',
                      value: date,
                      e: $event,
                      champName: 'date'
                    })
                  "
                ></date-picker>
              </b-form-group>
              <b-form-group
                label="Date de reglement"
                label-for="title-template-swift"
                class=" input-modal-champ "
              >
                <date-picker
                  value-type="format"
                  format="DD-MM-YYYY"
                  type="date"
                  :value="date_reglement"
                  class="inputDateTemplate"
                  @input="
                    changeFieldsValue({
                      key: 'date_reglement_id',
                      value: date_reglement,
                      e: $event,
                      champName: 'date_reglement'
                    })
                  "
                ></date-picker>
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Description "
                label-for="title-template-description"
                class=" input-modal-champ "
              >
                <b-form-textarea
                  id="title-template-description"
                  v-model="getTemplateFilialeToUpload.description"
                  @input="
                    changeFieldsValue({
                      key: 'description_id',
                      value: getTemplateFilialeToUpload.description
                    })
                  "
                  rows="5"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <!-- <div>
              <b-form-group
                label="IBAN "
                label-for="title-template-iban"
                class=" input-modal-champ "
              >
                <b-form-input
                  autocomplete="off"
                  id="title-template-iban"
                  v-model="getTemplateFilialeToUpload.iban"
                  @input="
                    changeFieldsValue({
                      key: 'iban_id',
                      value: getTemplateFilialeToUpload.iban
                    })
                  "
                  disabled
                ></b-form-input>
              </b-form-group>
            </div> -->
            <!-- <div>
              <b-form-group
                label="Swift "
                label-for="title-template-swift"
                class=" input-modal-champ "
              >
                <b-form-input
                  autocomplete="off"
                  id="title-template-swift"
                  v-model="getTemplateFilialeToUpload.swift"
                  @input="
                    changeFieldsValue({
                      key: 'swift_id',
                      value: getTemplateFilialeToUpload.swift
                    })
                  "
                  disabled
                ></b-form-input>
              </b-form-group>
            </div> -->

            <!-- Rib -->
            <b-col col xs="12" sm="12" md="12">
              <!-- Rib  --><b-form-group
                label="Rib"
                label-for="rib-template-select"
              >
                <multiselect
                  v-model="getTemplateFilialeToUpload.rib"
                  :options="getTemplateFilialeToUpload.ribs"
                  :multiple="false"
                  label="rib"
                  track-by="rib"
                  required
                  class="b-form-select-raduis"
                  :showLabels="false"
                >
                </multiselect>
              </b-form-group>
            </b-col>
          </div>
        </div>
        <div
          id="hmt_template_block_id"
          class="template-style"
          v-if="getTemplateFilialeToUpload"
          v-html="getTemplateFilialeToUpload.content"
        ></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    year: { required: true },
    month: { required: true },
    type: { required: false },
    dataToUse: { required: true }
  },
  data() {
    return {
      loadingUpload: false,
      errorUpload: null,
      date: null,
      date_reglement: null
    };
  },
  methods: {
    ...mapActions([
      'uploadGlobalTemplateInServieVisionFiliale',
      'getGlobalTemplateAndFiledsDataToUpdate',
      'setDaraTemplateUploded'
    ]),
    async handleValidGlobal() {
      let now = new Date();
      let dateFormate =
        now.getDate() + '-' + (now.getMonth() + 1) + '-' + now.getFullYear();
      dateFormate = moment(dateFormate, 'DD-MM-YYYY').format('DD-MM-YYYY');
      this.date = dateFormate;
      this.date_reglement = dateFormate;
      this.$refs['uploadFactureTemplateModal'].show();
      await this.getGlobalTemplateAndFiledsDataToUpdate({
        type: this.computedType
      });
    },
    async handleUpload() {
      if (
        this.getTemplateFilialeToUpload &&
        this.getTemplateFilialeToUpload.rib != null
      ) {
        this.loadingUpload = true;
        this.errorUpload = null;
        var bodyFormData = new FormData();
        bodyFormData.append('year', this.year);
        bodyFormData.append('month', this.month);
        bodyFormData.append('type', this.computedType);
        bodyFormData.append(
          'description',
          this.getTemplateFilialeToUpload.description
        );
        bodyFormData.append('iban', this.getTemplateFilialeToUpload.rib.iban);
        // bodyFormData.append('rib', this.getTemplateFilialeToUpload.rib);
        bodyFormData.append('swift', this.getTemplateFilialeToUpload.rib.swift);
        bodyFormData.append(
          'num_facture',
          this.getTemplateFilialeToUpload.num_facture
        );
        bodyFormData.append('date', this.date);
        bodyFormData.append('date_reglement', this.date_reglement);
        bodyFormData.append('column', this.computedColumn);
        let dataToUse = this.dataToUse.filter(k => {
          return k.projects.some(e => e.check == true);
        });
        let indexToUse = 0;
        dataToUse = dataToUse.sort((a, b) => a.nom.localeCompare(b.nom));
        for (let i = 0; i < dataToUse.length; i++) {
          bodyFormData.append(
            'filiales[' + [i] + '][filiale]',
            dataToUse[i].nom
          );
          for (let j = 0; j < dataToUse[i].projects.length; j++) {
            if (dataToUse[i].projects[j].check == true) {
              bodyFormData.append(
                'filiales[' + [i] + '][users][' + [indexToUse] + ']',
                dataToUse[i].projects[j].nom_pernom
              );
              bodyFormData.append(
                'filiales[' + [i] + '][project_id][' + [indexToUse] + ']',
                dataToUse[i].projects[j].id
              );
              indexToUse = indexToUse + 1;
            }
          }
        }
        const response = await this.uploadGlobalTemplateInServieVisionFiliale(
          bodyFormData
        );

        if (response.succes) {
          this.loadingUpload = false;
          this.errorUpload = null;
          this.hideModal('uploadFactureTemplateModal');
          let errorMessage =
            response.message.errors && response.message.errors > 0
              ? '\n' +
                '<div>Factures non validé : ' +
                response.message.errors +
                ' <span style="font-size: 18px;border-radius: 50%;background-color: #ff0000d9;padding: 1px 7px;color: #fff;" title="Total zéro">!</span></div>'
              : '';
          let text =
            '<div style="dispaly:flex"><div>Factures validé avec succès  : ' +
            response.message.success +
            '\n' +
            '\n' +
            '</div><div>Factures déja payé : ' +
            response.message.already_paied +
            '</div>' +
            errorMessage;

          this.$alert('', text, 'success').then(() => {
            this.$emit('reloadData');
          });
        } else {
          this.errorUpload = response.error;
          this.loadingUpload = false;
        }
      } else {
        this.errorUpload = 'Une erreur est servenue ! RIB obligatoire';
      }
    },
    changeFieldsValue(item) {
      this[item.champName] = item.e;
    },
    resetModal() {
      this.loadingUpload = false;
      this.errorUpload = null;
      this.setDaraTemplateUploded();
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    }
  },
  computed: {
    ...mapGetters([
      'getTemplateFilialeToUpload',
      'getLoadingGetDataTemplate',
      'getErrorTemplateDataToUpploadeed'
    ]),
    computedColumn() {
      switch (this.type) {
        case 'NOVA':
          return 'operationnel';
        case 'AXELIUS':
          return 'marketing';
        case 'ISO 33':
          return 'technique';
        case 'CERTILIA':
          return 'rge';
        case 'GE CDG':
          return 'CDG';
        case 'SAMI-B':
          return 'administratif_sav';
        case 'WEB ABONDANCE':
          return 'dsi';
        case 'CORPEDYS':
          return 'juridique';
        case 'ALTEO France':
          return 'alger';
        case 'NOVAE MAIRIE':
          return 'mairie';
        case 'GSE':
          return 'maintenance_pac';
        case 'UPCUBE TECHNOLOGIES':
          return 'upcube';
        case 'Alteo contrôle qualité':
          return 'alteo_control_gestion';
        default:
          return this.type;
      }
    },
    computedType() {
      switch (this.type) {
        case 'NOVA':
          return 'NOVAE';
        case 'AXELIUS':
          return 'WEB ABONDAN';
        case 'ISO 33':
          return 'ISO 33';
        case 'CERTILIA':
          return 'RGE';
        case 'GE CDG':
          return 'DNH';
        case 'SAMI-B':
          return 'SAV-S';
        case 'WEB ABONDANCE':
          return 'WEB ABONDANCE';
        case 'CORPEDYS':
          return 'CORPEDYS';
        case 'ALTEO France':
          return 'ACTEO';
        case 'GE COMPTA':
          return 'GE COMPTA';
        case 'GE RH':
          return 'GE RH';
        case 'KYNAN - ISO 33 - ISAUDIT':
          return 'KYNAN - ISO 33 - ISAUDIT';
        case 'NOVAE MAIRIE':
          return 'NOVAE MAIRIE';
        case 'GSE':
          return 'GSE';
        case 'UPCUBE TECHNOLOGIES':
          return 'upcube';
        case 'Alteo contrôle qualité':
          return 'alteo_control_gestion';
        default:
          return this.type;
      }
    }
  },
  components: {
    DatePicker
  }
};
</script>

<style lang="scss" scoped>
.cutsom-modal-bootstrap .modal-dialog {
  top: 0%;
  max-width: 100%;
  margin: 5px;
}
.global-template-block-style {
  display: flex;
  .input-fields-style-for-template {
    width: 39%;
    background-color: #ecedec36;
    margin: 0% 2%;
    padding: 0% 1%;
    border-radius: 16px;
    .fields-input {
      display: inline;
      label {
        font-size: 13px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        text-align: start;
        color: #2a2a2a;
        margin-left: 0%;
        margin-top: 0px;
        margin-bottom: 5px;
      }
    }
  }
  .template-style {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 65px);
    width: 62%;
    padding-right: 6px;
  }
}
</style>
